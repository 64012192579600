<template>
    <div class="container-fluid my-3">
        <!-- Table -->
        <div class="card mt-2">
            <div class="card-content">
                <el-table
                    sticky
                    :sticky-offset-top="167"
                    ref="settingsTable"
                    v-loading="symbolSettingsLoadingState"
                    class="settings-table"
                    :data="symbolSettings"
                    @cell-click="cellClick"
                    cell-class-name="cursor-pointer"
                    @sort-change="handleTableSortChange"
                    style="width: 100%">
                    <el-table-column
                        sortable="custom"
                        prop="account.name"
                        label="Account"
                        fixed
                        width="100px"
                        :formatter="this.tableValueFormatter"
                    >
                    </el-table-column>
                    <el-table-column
                        v-for="(column, columnIndex) in settingsTableColumns"
                        :key="`column-${columnIndex}`"
                        :prop="column.property"
                        :label="column.label"
                        :fixed="column.fixed"
                        :width="column.width"
                        :formatter="column.formatter"
                    >
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <!-- Update settings dialog -->
        <el-dialog
            title="Edit settings"
            :visible.sync="isSettingsUpdateDialogVisible"
            width="300px">
            <div class="text-center">
                <p>Account: {{ settingUpdateForm.account }}</p>
                <p>Symbol: {{ settingUpdateForm.symbol }}</p>
                <p>Setting: {{ settingUpdateForm.propertyLabel }}</p>
            </div>
            <!-- Boolean -->
            <div v-if="settingUpdateForm.propertyType === 'boolean'" class="text-center">
                <el-switch
                    v-model="settingUpdateForm.propertyValue"
                    :active-value="1"
                    :inactive-value="0"
                    active-text="On"
                    inactive-text="Off">
                </el-switch>
            </div>
            <!-- Double -->
            <div v-if="settingUpdateForm.propertyType === 'double'" class="text-center">
                <el-input-number
                    size="small"
                    :precision="2"
                    v-model="settingUpdateForm.propertyValue"
                    :label="settingUpdateForm.propertyLabel"
                    controls-position="right"
                />
            </div>
            <!-- Integer -->
            <div v-if="settingUpdateForm.propertyType === 'integer'" class="text-center">
                <el-input-number
                    size="small"
                    :precision="0"
                    v-model="settingUpdateForm.propertyValue"
                    :label="settingUpdateForm.propertyLabel"
                    controls-position="right"
                />
            </div>
            <!-- Select -->
            <div v-if="settingUpdateForm.propertyType === 'select'" class="text-center">
                <el-select
                    size="small"
                    v-model="settingUpdateForm.propertyValue"
                    :placeholder=" settingUpdateForm.propertyLabel">
                    <el-option
                        v-for="item in settingUpdateForm.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <!-- String -->
            <div v-if="settingUpdateForm.propertyType === 'string'" class="text-center">
                <el-input
                    size="small"
                    v-model="settingUpdateForm.propertyValue"
                    :placeholder=" settingUpdateForm.propertyLabel"/>
            </div>
            <!-- Date -->
            <div v-if="settingUpdateForm.propertyType === 'date'" class="text-center">
                <el-date-picker
                    type="date"
                    size="small"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="settingUpdateForm.propertyValue"
                    :placeholder=" settingUpdateForm.propertyLabel"/>
            </div>
            <!-- Time -->
            <div v-if="settingUpdateForm.propertyType === 'time'" class="text-center">
                <el-time-picker
                    type="date"
                    size="small"
                    format="HH:mm"
                    value-format="HH:mm"
                    v-model="settingUpdateForm.propertyValue"
                    :placeholder=" settingUpdateForm.propertyLabel"/>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="isSettingsUpdateDialogVisible = false">Cancel</el-button>
            <el-button size="small" type="primary" @click="updateSingleSetting" :loading="updateSymbolSettingsLoadingState">Save</el-button>
          </span>
        </el-dialog>
        <!-- Apply to all dialog -->
        <el-dialog
            title="Apply to all"
            :visible.sync="isApplyToAllDialogVisible"
            width="30%">
            <p>Account: {{ settingsApplyAllForm.account }}</p>
            <p>Symbol: {{ settingsApplyAllForm.symbol }}</p>

            <div v-for="(value, key) in settingsApplyAllForm.settingsToUpdate" :key="key">
                <span>{{settingLabel(key)}}: </span>
                <span>{{settingValueFormatter(key, settingsApplyAllForm.settingsToUpdate[key])}}</span>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="isApplyToAllDialogVisible = false">Cancel</el-button>
            <el-button type="primary" @click="applyToAll" :loading="updateSymbolSettingsLoadingState">Apply to all</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import Vue from 'vue'
import StickyTable from 'element-ui-sticky-table'
Vue.component('el-table', StickyTable)

import {dateTimeToDateString} from '@/common/helpers'

const plPendingOrdersTrailingOptions = [
    {
        value: 0,
        label: 'Stop',
    },
    {
        value: 1,
        label: 'Limit',
    },
    {
        value: 2,
        label: 'Limit+Stop',
    },
    {
        value: 3,
        label: 'Off',
    }
]
const plOpenOrdersTypesOptions = [
    {
        value: 0,
        label: 'No',
    },
    {
        value: 1,
        label: 'Limit',
    },
    {
        value: 2,
        label: 'Stop',
    },
    {
        value: 3,
        label: 'Limit+Stop',
    }
]
const plAirSignalTypeOptions = [
    {
        value: 0,
        label: 'No',
    },
    {
        value: 1,
        label: 'M1+M5+M15 MACD',
    },
    {
        value: 2,
        label: 'M5+M15 MACD',
    },
    {
        value: 3,
        label: 'M1+M5+M15+H1 MACD',
    },
    {
        value: 4,
        label: 'M5+M15+H1 MACD',
    },
    {
        value: 5,
        label: 'M1+M5+M15 Parabolic',
    },
    {
        value: 6,
        label: 'M5+M15 Parabolic',
    },
    {
        value: 7,
        label: 'M1+M5+M15+H1 Parabolic',
    },
    {
        value: 8,
        label: 'M5+M15+H1 Parabolic',
    },
]
const plAirTrendTypeOptions = [
    {
        value: 0,
        label: 'IchimokuSimple',
    },
    {
        value: 1,
        label: 'IchimokuNet',
    },
    {
        value: 2,
        label: 'IchimokuMACD',
    },
    {
        value: 3,
        label: 'Reserved (3)',
    },
]
const fsTradeDirectionOptions = [
    {
        value: 0,
        label: 'Both',
    },
    {
        value: 2,
        label: 'Buy',
    },
    {
        value: 3,
        label: 'Sell',
    },
    {
        value: 4,
        label: 'No',
    },
]
const fsPendingOrdersTrailingOptions = [
    {
        value: 0,
        label: 'Stop',
    },
    {
        value: 1,
        label: 'Limit',
    },
    {
        value: 2,
        label: 'Limit+Stop',
    },
    {
        value: 3,
        label: 'Off',
    },
]
const mnTradeDirectionOptions = [
    {
        value: 0,
        label: 'Both'
    },
    {
        value: 4,
        label: 'Auto'
    }
]
const mnUseIchimokuOptions = [
    {
        value: 0,
        label: 'No'
    },
    {
        value: 1,
        label: 'H1'
    },
    {
        value: 2,
        label: 'H1 + H4'
    },
    {
        value: 3,
        label: 'H1 + H4 + D1'
    },
    {
        value: 4,
        label: 'H4 + D1'
    },
]

export default {
    name: 'Settings',
    data: function () {
        return {
            selectedSettingsType: 'general',
            settingsTypes: [
                { value: 'general', label: 'General' },
                { value: 'passiveLock', label: 'Passive lock' },
                { value: 'air', label: 'Air' },
                { value: 'fishing', label: 'Fishing' },
                { value: 'martin', label: 'Martin' },
                { value: 'signals', label: 'Signals' },
                { value: 'autoClose', label: 'Auto close' },
                { value: 'partClosure', label: 'Part closure' },
            ],
            settingsTypesColumns: [
                // General
                { group: 'general', property: 'symbol.symbol', label: 'Symbol', fixed: true, width: '80px', formatter: this.tableValueFormatter, type: null },
                { group: 'general', property: 'gn_min_margin_level', label: 'Min margin level', fixed: false, width: '120px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'general', property: 'gn_max_lots_in_one_direction', label: 'Max lots', fixed: false, width: '100px', formatter: this.tableValueFormatter, type: 'double' },
                { group: 'general', property: 'gn_max_asymmetry', label: 'Max asymmetry', fixed: false, width: '100px', formatter: this.tableValueFormatter, type: 'double' },
                { group: 'general', property: 'gn_auto', label: 'Auto', fixed: false, width: '100px', formatter: this.tableValueFormatter, type: 'boolean' },
                // Passive Lock
                { group: 'passiveLock', property: 'symbol.symbol', label: 'Symbol', fixed: true, width: '80px', formatter: this.tableValueFormatter, type: null },
                { group: 'passiveLock', property: 'pl_state', label: 'State', fixed: true, width: '80px', formatter: this.tableValueFormatter, type: 'boolean' },
                { group: 'passiveLock', property: 'pl_max_profit', label: 'Profit', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'double' },
                { group: 'passiveLock', property: 'pl_max_profit_coefficient', label: 'Coefficient', fixed: false, width: '100px', formatter: this.tableValueFormatter, type: 'double' },
                { group: 'passiveLock', property: 'pl_limit_order_distance', label: 'Limit distance', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'passiveLock', property: 'pl_stop_order_distance', label: 'Stop distance', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'passiveLock', property: 'pl_pending_orders_trailing', label: 'Orders trailing', fixed: false, width: '100px', formatter: this.tableValueFormatter, type: 'select', options: plPendingOrdersTrailingOptions },
                { group: 'passiveLock', property: 'pl_open_orders_types', label: 'Open orders', fixed: false, width: '100px', formatter: this.tableValueFormatter, type: 'select', options: plOpenOrdersTypesOptions },
                // Air
                { group: 'air', property: 'symbol.symbol', label: 'Symbol', fixed: true, width: '80px', formatter: this.tableValueFormatter, type: null },
                { group: 'air', property: 'pl_air_state', label: 'State', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'boolean' },
                //{ group: 'air', property: 'pl_air_lots', label: 'Lots', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'double' },
                //{ group: 'air', property: 'pl_air_signal_type', label: 'Signal', fixed: false, width: '200px', formatter: this.tableValueFormatter, type: 'select', options: plAirSignalTypeOptions },
                { group: 'air', property: 'pl_air_trend_type', label: 'Trend', fixed: false, width: '150px', formatter: this.tableValueFormatter, type: 'select', options: plAirTrendTypeOptions },
                // Fishing
                { group: 'fishing', property: 'symbol.symbol', label: 'Symbol', fixed: true, width: '80px', formatter: this.tableValueFormatter, type: null },
                { group: 'fishing', property: 'fs_state', label: 'State', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'boolean' },
                { group: 'fishing', property: 'fs_lot', label: 'Lots', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'double' },
                { group: 'fishing', property: 'fs_trade_direction', label: 'Direction', fixed: false, width: '90px', formatter: this.tableValueFormatter, type: 'select', options: fsTradeDirectionOptions },
                { group: 'fishing', property: 'fs_pending_orders_trailing', label: 'Orders trailing', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'select', options: fsPendingOrdersTrailingOptions },
                { group: 'fishing', property: 'fs_trailing_stop', label: 'Trailing stop', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'fishing', property: 'gn_min_deviation', label: 'Min deviation', fixed: false, width: '90px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'fishing', property: 'fs_sl_trailing_state', label: 'SL trailing', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'boolean' },
                // Martin
                { group: 'martin', property: 'symbol.symbol', label: 'Symbol', fixed: true, width: '80px', formatter: this.tableValueFormatter, type: null },
                { group: 'martin', property: 'mn_state', label: 'State', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'boolean' },
                { group: 'martin', property: 'mn_lots', label: 'Lots', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'double' },
                { group: 'martin', property: 'mn_averaging_step', label: 'Averaging step', fixed: false, width: '90px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'martin', property: 'mn_change_lot_step_percent', label: 'Lot/Step change percent', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'martin', property: 'mn_trailing_stop', label: 'Trailing stop', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'martin', property: 'mn_trade_direction', label: 'Direction', fixed: false, width: '90px', formatter: this.tableValueFormatter, type: 'select', options: mnTradeDirectionOptions },
                { group: 'martin', property: 'mn_target_specific_profit', label: 'Specific profit', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'double' },
                { group: 'martin', property: 'mn_extra_ticket_and_lots', label: 'Extra ticket (lots)', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'string' },
                { group: 'martin', property: 'mn_use_accumulated_profit', label: 'Use accumulated profit', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'boolean' },
                { group: 'martin', property: 'mn_close_last_position_number', label: 'Close last position number', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'martin', property: 'mn_max_onedirectional_positions_count', label: 'Max positions', fixed: false, width: '90px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'martin', property: 'mn_use_ichimoku', label: 'Use Ichimoku', fixed: false, width: '90px', formatter: this.tableValueFormatter, type: 'select', options: mnUseIchimokuOptions },
                // Signals
                { group: 'signals', property: 'symbol.symbol', label: 'Symbol', fixed: true, width: '80px', formatter: this.tableValueFormatter, type: null },
                { group: 'signals', property: 'sg_state', label: 'State', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'boolean' },

                // Auto close
                { group: 'autoClose', property: 'symbol.symbol', label: 'Symbol', fixed: true, width: '80px', formatter: this.tableValueFormatter, type: null },
                { group: 'autoClose', property: 'cl_state', label: 'State', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'boolean' },
                { group: 'autoClose', property: 'cl_type', label: 'Auto', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'boolean' },
                { group: 'autoClose', property: 'cl_tickets', label: 'Tickets', fixed: false, width: '200px', formatter: this.tableValueFormatter, type: 'string' },
                { group: 'autoClose', property: 'cl_target_profit', label: 'Target profit', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'double' },
                { group: 'autoClose', property: 'cl_lots', label: 'Lots (auto)', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'double' },
                { group: 'autoClose', property: 'cl_profit_from_date', label: 'Profit from', fixed: false, width: '100px', formatter: this.tableValueFormatter, type: 'date' },

                // Part closure
                { group: 'partClosure', property: 'symbol.symbol', label: 'Symbol', fixed: true, width: '80px', formatter: this.tableValueFormatter, type: null },
                { group: 'partClosure', property: 'ptcl_state', label: 'State', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'boolean' },
                { group: 'partClosure', property: 'ptcl_min_profit_balance', label: 'Min profit / balance', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'double' },
                { group: 'partClosure', property: 'ptcl_neg_sw_min_deviation', label: 'Min deviation negative swap', fixed: false, width: '150px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'partClosure', property: 'ptcl_pos_sw_min_deviation', label: 'Min deviation positive swap', fixed: false, width: '150px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'partClosure', property: 'ptcl_profit_days', label: 'Profit days', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'partClosure', property: 'ptcl_profit_percentage', label: 'Profit percentage', fixed: false, width: '100px', formatter: this.tableValueFormatter, type: 'integer' },
                { group: 'partClosure', property: 'ptcl_close_time', label: 'Close time', fixed: false, width: '80px', formatter: this.tableValueFormatter, type: 'time' },
                { group: 'partClosure', property: 'ptcl_ticket', label: 'Ticket', fixed: false, width: '200px', formatter: this.tableValueFormatter, type: 'string' },

            ],
            settingsTableColumns: [],

            isSettingsUpdateDialogVisible: false,
            settingUpdateForm: {
                symbolSettingId: null,
                account: null,
                symbol: null,
                property: null,
                propertyType: null,
                propertyLabel: null,
                propertyValue: null,
            },

            isApplyToAllDialogVisible: false,
            settingsApplyAllForm: {
                symbolSettingsId: null,
                account: null,
                symbol: null,
            },

            reloadSettingsTimer: null,

            accountNameSortingOrder: 'ascending',
        }
    },
    computed: {
        selectedAccount: function () {
            return this.$store.getters.ADMIN_SELECTED_ACCOUNT
        },
        selectedAccounts: function () {
            return this.$store.getters['admin/accountsSettings/selectedAccounts']
        },
        symbolSettings: function () {
            // Deep copy
            let settings = JSON.parse(JSON.stringify(this.$store.getters['admin/symbolSettings']))

            settings.sort((a, b) => {
                if(this.accountNameSortingOrder) {
                    if(this.accountNameSortingOrder === 'ascending') {
                        return a['account']['name'].localeCompare(b['account']['name'])
                    }
                    if(this.accountNameSortingOrder === 'descending') {
                        return b['account']['name'].localeCompare(a['account']['name'])
                    }
                }

                return 0
            })

            let prevName = name
            settings.forEach((setting, index) => {
                if(!prevName) {
                    prevName = setting.account.name
                    return
                }

                if(prevName === setting.account.name) {
                    settings[index].account.name = ''
                } else {
                    prevName = settings[index].account.name
                }
            })
            return settings
        },
        symbolSettingsLoadingState: function () {
            return this.$store.getters['admin/symbolSettingsLoadingState']
        },
        updateSymbolSettingsLoadingState: function () {
            return this.$store.getters['admin/updateSymbolSettingsLoadingState']
        }
    },
    methods: {
        setSettingsTableColumns: function (type) {
            // If settings type not defined -> set default settings
            if(!type) {
                this.$router.replace({
                    path: '/admin/administration/settings',
                    query: {
                        type: this.$store.getters['admin/symbolSettingsSettings/defaultSettingsType']
                    }
                })

                return
            }
            this.selectedSettingsType = type ?? 'general'
            this.settingsTableColumns = []

            setTimeout(() => {
                const settings = this.settingsTypesColumns.filter(settingsTypeColumns => settingsTypeColumns.group === this.selectedSettingsType)
                if(settings.length) {
                    this.settingsTableColumns = settings
                }
            },0)
        },
        handleTableSortChange: function ({ prop, order }) {
            switch (prop) {
                case 'account.name':
                    this.accountNameSortingOrder = order
                    break
            }
        },
        cellClick: function (row, column) {
            // Property to update
            const propertyToUpdate = column.property

            if(propertyToUpdate === 'account.name') {
                return
            }

            if(propertyToUpdate === 'symbol.symbol') {
                // What settings fields needs to be updated?
                let settingsToUpdate = this.settingsTypesColumns
                    .filter(setting => setting.group === this.selectedSettingsType)
                    .map(setting => setting.property)
                let updateSettingsObject = {}

                for (let key in row) {
                    if(Object.prototype.hasOwnProperty.call(row, key) && settingsToUpdate.includes(key)) {
                        updateSettingsObject[key] = row[key]
                    }
                }

                this.settingsApplyAllForm = {
                    symbolSettingId: row.symbol_settings_id,
                    account: row.account.name,
                    symbol: row.symbol.symbol,
                    settingsToUpdate: updateSettingsObject
                }

                this.isApplyToAllDialogVisible = true
                return
            }

            // Get column description
            const columnDescription = this.settingsTypesColumns.find(column => column.property === propertyToUpdate)
            if(!columnDescription) {
                return
            }

            this.settingUpdateForm = {
                symbolSettingId: row.symbol_settings_id,
                account: row.account.name,
                symbol: row.symbol.symbol,
                property: columnDescription.property,
                propertyType: columnDescription.type,
                propertyLabel: columnDescription.label,
                propertyValue: row[columnDescription.property],
                options: columnDescription.options,
            }

            this.isSettingsUpdateDialogVisible = true
        },
        tableValueFormatter: function (row, column, cellValue) {
            return this.settingValueFormatter(column.property, cellValue)
        },
        settingValueFormatter: function (property, value) {
            let option = null
            switch(property) {
                case 'gn_auto':
                    return value ? 'On' : 'Off'
                // PL
                case 'pl_state':
                    return value ? 'On' : 'Off'
                case 'pl_max_profit':
                    return value.toFixed(2)
                case 'pl_max_profit_coefficient':
                    return value.toFixed(2)
                case 'pl_pending_orders_trailing':
                    option = plPendingOrdersTrailingOptions.find(option => option.value === value)
                    return option ? option.label : value
                case 'pl_open_orders_types':
                    option = plOpenOrdersTypesOptions.find(option => option.value === value)
                    return option ? option.label : value
                case 'pl_air_signal_type':
                    option = plAirSignalTypeOptions.find(option => option.value === value)
                    return option ? option.label : value
                case 'pl_air_trend_type':
                    option = plAirTrendTypeOptions.find(option => option.value === value)
                    return option ? option.label : value
                case 'pl_air_state':
                    return value ? 'On' : 'Off'
                case 'pl_air_lots':
                    return value.toFixed(2)
                // FS
                case 'fs_state':
                    return value ? 'On' : 'Off'
                case 'fs_trade_direction':
                    option = fsTradeDirectionOptions.find(option => option.value === value)
                    return option ? option.label : value
                case 'fs_pending_orders_trailing':
                    option = fsPendingOrdersTrailingOptions.find(option => option.value === value)
                    return option ? option.label : value
                case 'fs_sl_trailing_state':
                    return value ? 'On' : 'Off'
                // MN
                case 'mn_state':
                    return value ? 'On' : 'Off'
                case 'mn_trade_direction':
                    option = mnTradeDirectionOptions.find(option => option.value === value)
                    return option ? option.label : value
                case 'mn_use_accumulated_profit':
                    return value ? 'On' : 'Off'
                case 'mn_use_ichimoku':
                    option = mnUseIchimokuOptions.find(option => option.value === value)
                    return option ? option.label : value
                // Signals
                case 'sg_state':
                    return value ? 'On' : 'Off'
                // Auto close
                case 'cl_state':
                    return value ? 'On' : 'Off'
                case 'cl_type':
                    return value ? 'On' : 'Off'
                case 'cl_profit_from_date':
                    return dateTimeToDateString(value)
                // Part closure
                case 'ptcl_state':
                    return value ? 'On' : 'Off'

                default:
                    return value
            }
        },
        settingLabel: function (property) {
            const setting = this.settingsTypesColumns.find(setting => setting.property === property)
            return setting ? setting.label : property
        },
        updateSingleSetting: function () {
            let data = {}
            data['symbol_settings_id'] = this.settingUpdateForm.symbolSettingId
            data[this.settingUpdateForm.property] = this.settingUpdateForm.propertyValue

            this.$store.dispatch('admin/updateSymbolSetting', data)
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        message: 'Setting update error'
                    })
                })
                .finally(() => {
                    this.isSettingsUpdateDialogVisible = false
                })
        },
        applyToAll: function () {
            let data = {}
            data['symbol_settings_id'] = this.settingsApplyAllForm.symbolSettingId
            data['apply_to_all_accounts_with_this_symbol'] = true
            for(let key in this.settingsApplyAllForm.settingsToUpdate) {
                data[key] = this.settingsApplyAllForm.settingsToUpdate[key]
            }

            this.$store.dispatch('admin/updateSymbolSetting', data)
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        message: 'Setting update error'
                    })
                })
                .finally(() => {
                    this.isApplyToAllDialogVisible = false
                    this.reloadSymbolSettings()
                })
        },
        reloadSymbolSettings: function (isFirstLoading = false) {
            if(isFirstLoading) {
                this.$store.commit('admin/setSymbolSettingsLoadingState', true)
            }
            this.$store.dispatch('admin/getSymbolSettings', {
                account_ids: this.selectedAccounts.join(',')
            })
                .then(() => {
                    if(isFirstLoading && this.selectedAccount && this.selectedAccount.account_id) {
                        // Find first index of required row
                        const rowIndex = this.symbolSettings.findIndex(setting => setting.account_id === this.selectedAccount.account_id)
                        if(rowIndex !== -1) {
                            window.scrollTo({
                                top: this.$refs.settingsTable.$el.getBoundingClientRect().top + rowIndex * 45 - 167
                            })
                        }
                    }
                })
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        message: 'Setting reload error'
                    })
                })
            .finally(() => {
                if(isFirstLoading) {
                    this.$store.commit('admin/setSymbolSettingsLoadingState', false)
                }
            })
        }
    },
    beforeRouteUpdate: function (to, from, next) {
        this.setSettingsTableColumns(to.query.type)
        next()
    },
    async mounted() {
        // Set default settings options
        this.$store.commit('admin/symbolSettingsSettings/setDefaultSettingsTypeOptions', this.settingsTypes)

        this.setSettingsTableColumns(this.$route.query.type)

        // Get symbol settings
        this.reloadSymbolSettings(true)
        this.reloadSettingsTimer = setInterval(this.reloadSymbolSettings, 10000)
    },
    beforeDestroy() {
        clearInterval(this.reloadSettingsTimer)
    }
}
</script>

<style scoped>
.settings-table >>> .cell{
    word-break: break-word;
}
.settings-table >>> .cursor-pointer {
    cursor: pointer;
}

.settings-table >>> .el-table__body .cell {
    height: 20px !important;
}

</style>
